<template>

  <v-row class="row-bg-lightblue-t">

      <v-tabs centered show-arrows color="indigo">
        <v-tab to="/admin"><v-icon>mdi-account-cowboy-hat</v-icon></v-tab>
        <v-tab to="/admin/reservations">Réservations</v-tab>
        <v-tab to="/admin/commandes">Commandes</v-tab>
        <v-tab to="/admin/data/product">Données</v-tab>
        <v-tab to="/admin/stats">Stats</v-tab>
        <v-tab to="/admin/trafic">Trafic</v-tab>
      </v-tabs>
      
      <v-col cols="12" color="transparent" elevation="0" v-if="false">
        <template v-for="item in $store.state.admin.entityTypesAvailables" >
          <v-btn class="mr-2 mb-2 btn-pix-admin" 
                 color="admin_secondary" outlined  :to="'/admin/'+item" :key="item">
            <v-icon small class="mr-1">mdi-database</v-icon> {{ item }}
          </v-btn>
        </template>
      </v-col>

  </v-row>

</template>

<script>
  import '@/assets/css/home.css'
  export default {}
</script>